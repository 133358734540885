import { Navigate } from "react-router-dom";
import { Step, useStoreActions } from "./state/globalStore";

const Elquicode = () => {
  const { setMissionStep } = useStoreActions((actions) => actions);

  setMissionStep(Step.ASK_ELQUICODE);

  return <Navigate to="/internal/mission" />;
};

export default Elquicode;
