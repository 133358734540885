import { useStoreActions, Step } from "./state/globalStore";
import { Navigate } from "react-router-dom";

const Geoloc1 = () => {
  const { setMissionStep } = useStoreActions((actions) => actions);

  setMissionStep(Step.GEOLOC1);

  return <Navigate to="/internal/mission" />;
};

export default Geoloc1;
