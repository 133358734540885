import { useStoreState, useStoreActions, Step } from "./state/globalStore";
import { Button } from "react-bootstrap";
import "./Mission.css";
import { useState } from "react";

const GEOLOC1_LAT_MAX = 50.84745436167975;
const GEOLOC1_LON_MIN = 4.360149220493307;
const GEOLOC1_LAT_MIN = 50.84390105888272;
const GEOLOC1_LON_MAX = 4.367823160898627;

// Home location for testing:
// const GEOLOC1_LAT_MAX = 50.835350829902495;
// const GEOLOC1_LON_MIN = 4.3877666282546945;
// const GEOLOC1_LAT_MIN = 50.832276875460664;
// const GEOLOC1_LON_MAX = 4.395184350501582;

export default function Mission() {
  const { missionStep } = useStoreState((state) => state);
  const { setMissionStep, triggerPrint, triggerSMS, triggerEmail } =
    useStoreActions((actions) => actions);
  const [startShake, setStartShake] = useState(false);
  const [incorrectShake, setIncorrectShake] = useState(false);
  const [elquicode, setElquicode] = useState("");
  const [sherlockcode, setSherlockcode] = useState("");
  const [pathcode, setPathcode] = useState("");
  const [finalcode, setFinalcode] = useState("");
  const [locationLoading, setLocationLoading] = useState(false);
  const [location, setLocation] = useState<GeolocationPosition | {}>({});

  const handleAnimationEnd = () => {
    setIncorrectShake(false);
  };

  const handleStartButtonClick = () => {
    setStartShake(true);
    setTimeout(() => {
      setStartShake(false);
      setMissionStep(Step.FIRST);
    }, 3000); // 3 seconds delay
  };

  const handleFinalcodeButtonClick = () => {
    if (finalcode.toLowerCase() === "elendil") {
      setMissionStep(Step.FINAL);
    } else {
      setIncorrectShake(true);
    }
  };

  const handlePathcodeButtonClick = () => {
    // It's correct if it contains any of the words "bread", "pain", "patisserie", "bakery", "boulangerie", "fleur":

    console.log(pathcode);
    const pathcodeLower = pathcode.toLowerCase();
    const pathcodeWords = pathcodeLower.split(" ");
    const correctWords = [
      "bread",
      "pain",
      "patisserie",
      "bakery",
      "boulangerie",
      "fleur",
    ];

    const isCorrect = pathcodeWords.some((word) => correctWords.includes(word));

    if (isCorrect) {
      triggerEmail();
      setMissionStep(Step.AFTEREMAIL);
    } else {
      setIncorrectShake(true);
    }
  };

  const handleSherlockCodeButtonClick = () => {
    if (sherlockcode.toLowerCase() === "sherlocked") {
      setMissionStep(Step.AFTERSHERLOCKCODE);
    } else {
      setIncorrectShake(true);
    }
  };

  const handleElquicodeButtonClick = () => {
    if (elquicode === "537") {
      triggerPrint();
      setMissionStep(Step.AFTERELQUICODE);
    } else {
      setIncorrectShake(true);
    }
  };

  //   Check if location is within the bounds of geoloc1:
  console.log(location);
  if (missionStep === Step.GEOLOC1 && "coords" in location) {
    const { latitude, longitude } = location.coords;
    if (
      latitude >= GEOLOC1_LAT_MIN &&
      latitude <= GEOLOC1_LAT_MAX &&
      longitude >= GEOLOC1_LON_MIN &&
      longitude <= GEOLOC1_LON_MAX
    ) {
      setMissionStep(Step.FOUND_LOCATION);
      triggerSMS();
      console.log("Right spot!");
    } else {
      setIncorrectShake(true);
      setLocation({});
    }
  }
  const handleGeolocButtonClick = () => {
    setLocationLoading(true);
    navigator.geolocation.getCurrentPosition((position) => {
      setLocation(position);
      setLocationLoading(false);
    });
  };

  return (
    <div className="mission-container">
      <div
        className={`mission-card ${startShake ? "shake-animation" : ""} ${
          incorrectShake ? "incorrect-shake-animation" : ""
        }`}
        onAnimationEnd={handleAnimationEnd}
      >
        {missionStep === Step.START && (
          <>
            <h2>Welcome to your first assignment!</h2>
            <p>Dear C., </p>
            <p>
              C&P Investigations is happy to welcome you to your new venture.{" "}
            </p>
            <p>
              As you well know, there has been a large number of Memler
              sightings in your region recently and it appears that one of them
              has targeted you (and us...) directly! As such, your first
              assignment will be to handle the Memler that took domicile in your
              home. According to our most reliable intelligence, the bestway to
              manage these little creatures is to go through the little games
              they leave behind.{" "}
            </p>
            <p>
              Our technical team has been hard at work over the last weeks to
              provide a tool that will allow some sort of communication with
              Memlers. <br />
              Interestingly, they found out that the little beasts were very
              eager to communicate with us, and the best way to do so was to
              just let them take control of this website directly! <br />
            </p>
            <p>
              Remember as you go through your mission, that you can at anytime
              invoke your right to the <em>Omnipotent Hint Mechanism</em> if you
              feel stuck. Additionally, were you to encounter any technical
              difficulties, your partner P. has been briefed on the situation
              and will be able to help you out.
            </p>

            <p>Press the button below when you are ready to get started!</p>

            <Button
              variant="primary"
              onClick={handleStartButtonClick}
              style={{
                marginRight: "1rem",
              }}
            >
              Contact the Memler
            </Button>
          </>
        )}
        {missionStep === Step.FIRST && (
          <div className="inbasement poem">
            <p> Your Gift Is In The Basement...</p>
          </div>
        )}

        {missionStep === Step.ASK_ELQUICODE && (
          <>
            <h3> Did you find the code? </h3>
            <input
              type="text"
              placeholder="Code"
              value={elquicode}
              onChange={(e) => setElquicode(e.target.value)}
            />

            <Button
              variant="secondary"
              onClick={handleElquicodeButtonClick}
              style={{
                marginRight: "1rem",
              }}
            >
              Check
            </Button>
          </>
        )}
        {missionStep === Step.AFTERELQUICODE && (
          <>
            <h3> Well done! Keep going now...</h3>
          </>
        )}
        {missionStep === Step.GEOLOC1 && (
          <>
            <h3> Tell me when you're in the right spot...</h3>
            {locationLoading ? (
              <p> Loading... </p>
            ) : (
              <Button
                variant="primary"
                onClick={handleGeolocButtonClick}
                style={{
                  marginRight: "1rem",
                }}
              >
                I'm in the right spot!
              </Button>
            )}
          </>
        )}
        {missionStep === Step.FOUND_LOCATION && (
          <>
            <div className="missionpoem">
              <p>You will get a cipher, a code to decode,</p>
              <p>Three groups of numbers, a mysterious ode. </p>
              <p>Like in "National Treasure", the hints lie so grand, </p>
              <p>Page, line, and word, guide your seeking hand. </p>
              <p>
                {" "}
                <br />{" "}
              </p>
              <p>Holmes holds the secrets, nestled within, </p>
              <p>Decode the numbers, let the journey begin. </p>
              <p>You will have your path, but where should you start? </p>
              <p>Give me the password, and I'll do my part. </p>
            </div>

            <input
              type="text"
              placeholder="Password"
              value={sherlockcode}
              onChange={(e) => setSherlockcode(e.target.value)}
            />

            <Button
              variant="secondary"
              onClick={handleSherlockCodeButtonClick}
              style={{
                marginRight: "1rem",
              }}
            >
              Submit
            </Button>
          </>
        )}
        {missionStep === Step.AFTERSHERLOCKCODE && (
          <>
            <div className="missionpoem">
              <p> Continue on your journey, I'll help you when I must, </p>

              <p> But for now, I'll leave you with my trust. </p>
              <p> At the end of your path, what's right in front of you? </p>
              <p> Just give me the answer, and I'll know what to do. </p>
            </div>
            <input
              type="text"
              placeholder="What's in front of you?"
              value={pathcode}
              onChange={(e) => setPathcode(e.target.value)}
            />

            <Button
              variant="secondary"
              onClick={handlePathcodeButtonClick}
              style={{
                marginRight: "1rem",
              }}
            >
              What do I do now?
            </Button>
          </>
        )}
        {missionStep === Step.AFTEREMAIL && (
          <>
            <div className="missionpoem">
              <p> The mail you just received should let you know</p>
              <p> What it is that I expect you to do now. </p>
              <p> Godspeed to you for now, I'll be in touch, </p>
              <p> I hope you'll like the next part just as much. </p>
            </div>
          </>
        )}
        {missionStep === Step.X && (
          <>
            <h3> What's the code? </h3>
            <input
              type="text"
              placeholder="Code"
              value={finalcode}
              onChange={(e) => setFinalcode(e.target.value)}
            />

            <Button
              variant="secondary"
              onClick={handleFinalcodeButtonClick}
              style={{
                marginRight: "1rem",
              }}
            >
              Submit
            </Button>
          </>
        )}

        {missionStep === Step.FINAL && (
          <div className="missionpoem">
            <p> Congrats dear C. you're nearly there, </p>
            <p> Just one more thing, and we'll be square. </p>
            <p> Look where you found your latest prize, </p>
            <p> Your next clue might bring a surprise: </p>

            <p> You'll need to dig a little more, </p>
            <p> Around the place you looked before </p>
            <p> To find the last piece of the puzzle, </p>
            <p> And solve the mystery of the Memler's muzzle. </p>
          </div>
        )}
      </div>
    </div>
  );
}
