import Countdown from "react-countdown";
import { Link, Navigate } from "react-router-dom";
import Poems from "./poems";
import { useStoreState } from "./state/globalStore";

export default function Home() {
  // Current time:
  const now = new Date();

  // Opening date:
  const openingDate = new Date(2023, 9, 15, 6);

  const isAfterOpening = now > openingDate;

  const { isLoggedIn } = useStoreState((state) => state);
  return (
    <>
      <div className="news-flash">
        <i
          className="fa fa-exclamation-circle news-icon"
          aria-hidden="true"
        ></i>
        <p className="news-text">
          <b>News Flash:</b>Reports of Memlers spotted around Brussels! Come
          back soon for C&P Investigations' specialized reporting on the
          situation. <br />
          <Link to="/files/memlers" className="read-more">
            Read more about Memlers
          </Link>
          <br />
          <b>Update (02/10):</b> C&P Investigations has been working on a way to
          communicate with Memlers. Stay tuned! <br />
          <b>Update (03/10):</b> C&P's first contact with a Memler has been
          established! It turns out the Memler had already been hard at work
          leaving some clues on this website... C&P's technical team is working
          hard to provide its employees with the tools they need to investigate
          Memler cases. <br />
          <b>Update (04/10):</b> The Memler communication tool is turning out to
          be more challenging than we thought. Employees will be able to access
          it on the opening day{" "}
          <Link to="/internal/login" className="read-more">
            {" "}
            here
          </Link>
          .
        </p>
      </div>

      <header className="App-header">
        {/* If after opening: either give a link to login, or go directly to /internal/mission page if already logged in */}
        {isAfterOpening ? (
          isLoggedIn ? (
            <Navigate to="/internal/mission" />
          ) : (
            <>
              <Poems />
              <p> Welcome to C&P Investigations!</p>
              <p>
                {" "}
                Employees can login here:{" "}
                <Link to="/internal/login" className="read-more">
                  Login
                </Link>
              </p>
            </>
          )
        ) : (
          <>
            <p>It's not time yet...</p>
            <Countdown date={new Date(2023, 9, 15)} />
          </>
        )}
      </header>
    </>
  );
}
