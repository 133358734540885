import { StoreProvider } from "easy-peasy";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "@fontsource/libre-baskerville"; // Defaults to weight 400
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MemlersPage from "./MemlersPage";
import Home from "./Home";
import LoginPage from "./LoginPage";
import { store } from "./state/globalStore";
import Mission from "./Mission";
import Elquicode from "./Elquicode";
import Geoloc1 from "./Geoloc1";
import X from "./X";
import Goto from "./Goto";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/files/memlers",
        element: <MemlersPage />,
      },
      {
        path: "/internal/login",
        element: <LoginPage />,
      },
      {
        path: "/internal/mission",
        element: <Mission />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/internal/elquicode",
        element: <Elquicode />,
      },
      {
        path: "/internal/geoloc1",
        element: <Geoloc1 />,
      },
      {
        path: "/goto/:page",
        element: <Goto />,
      },
      {
        path: "/internal/finalcode",
        element: <X />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <RouterProvider router={router} />
    </StoreProvider>
  </React.StrictMode>
);
