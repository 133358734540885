import { Navigate, useParams } from "react-router-dom";
import { useStoreActions } from "./state/globalStore";

const Goto = () => {
  const { setMissionStep } = useStoreActions((actions) => actions);
  const { page } = useParams();
  // convert page to int:
  console.log(page);
  if (page === undefined) {
    return <Navigate to="/internal/mission" />;
  }
  const pageInt = parseInt(page);
  console.log(pageInt);

  setMissionStep(pageInt);

  return <Navigate to="/internal/mission" />;
};

export default Goto;
