import React from "react";
import "./MemlersPage.css"; // Optional, for additional styling

const MemlersPage = () => {
  return (
    <div className="memlers-page">
      <p
        style={{
          fontSize: "1rem",
        }}
      >
        C&P Investigations was able to retrieve the following information about
        the <em>Memler</em> from an unpublished page of{" "}
        <em>Fantastic Beasts and Where to Find Them</em>:
      </p>
      <div className="memler-info-box">
        <img src="/memler-small.png" alt="Memler" className="memler-image" />
        <h1>Memler</h1>
        <dl>
          <dt>Classification</dt>
          <dd>Beast, XXX</dd>

          <dt>Country of Origin</dt>
          <dd>Unknown</dd>

          <dt>Appearance</dt>
          <dd>Small, bird-footed, frog-faced with dual antenna-tipped eyes</dd>

          <dt>Primary Habitats</dt>
          <dd>Subterranean, memory-rich locales</dd>

          <dt>Temperament</dt>
          <dd>Benevolent, mischievous</dd>
        </dl>
        <h2>General Overview</h2>
        <p>
          The elusive Memler is seldom seen, visible only in dreams or altered
          states. Its bird-like feet with gripping claws allow it to hang from
          ceilings and walls. It has a frog-like face and an extra set of eyes
          on antenna-like stalks that allow it to see and feed on memories. It
          is a small creature that can fit in the palm of a hand. Although it
          has never directly seen in the physical world, it is able to interact
          with it by stealing items and leaving behind trails of clues.
        </p>

        <h2>Special Abilities</h2>
        <p>
          People used to think that Memlers fed on memories, but rather, it
          absorbs the energy created when people recall powerful memories. the
          Memler is a playful trickster, it steals cherished items and hides
          them away. To retrieve the stolen goods, one must journey through a
          maze of personal memories, enriching the creature with emotional
          energy.
        </p>

        <h2>Diet</h2>
        <p>Sustained by the emotional resonance found in revisited memories.</p>

        <h2>Notes</h2>
        <p>
          Encounters are intimate and usually benevolent, serving as a conduit
          for emotional retrospection. Still, exercise caution: it chooses to
          steal items you may hold dear.
        </p>
      </div>
    </div>
  );
};

export default MemlersPage;
