import React from "react";
import { useStoreActions, Step } from "./state/globalStore";
import { Navigate } from "react-router-dom";

const X = () => {
  const { setMissionStep } = useStoreActions((actions) => actions);

  setMissionStep(Step.X);

  return <Navigate to="/internal/mission" />;
};

export default X;
