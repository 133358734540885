import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { Button, Modal, Nav, Navbar } from "react-bootstrap";
import "./App.css";

import "font-awesome/css/font-awesome.min.css";
import { Outlet } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useStoreState } from "./state/globalStore";

function App() {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const { isLoggedIn } = useStoreState((state) => state);

  return (
    <div className="App">
      <Navbar
        bg="#000000"
        expand="lg"
        style={{
          backgroundColor: "#efefef",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Navbar.Brand
          href="/"
          style={{
            fontFamily: "Libre Baskerville",
            fontSize: "1.3rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="/logo_small.png"
            width="60"
            height="60"
            className="d-inline-block align-top"
            alt="C&P Investigations"
            style={{ marginRight: "1rem", marginLeft: "1rem" }}
          />
          C&P Investigations
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto">
            <Button
              variant="secondary"
              onClick={handleShow}
              style={{
                marginRight: "1rem",
              }}
            >
              About
            </Button>
            {isLoggedIn ? (
              <Button
                variant="secondary"
                disabled
                style={{
                  marginRight: "1rem",
                }}
              >
                Hi, C.!
              </Button>
            ) : (
              <Button
                variant="primary"
                href="/internal/login"
                style={{
                  marginRight: "1rem",
                }}
              >
                Login
              </Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Outlet />
      <Modal
        show={showModal}
        onHide={handleClose}
        style={{ fontFamily: "Georgia, serif" }}
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title style={{ fontSize: "1.5rem" }}>About Us</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ margin: "20px", fontSize: "1.2rem", lineHeight: "1.6" }}
        >
          C&P Investigations was formed in 2023 as the merger of{" "}
          <em>Puf Consulting, Ltd.</em> and{" "}
          <em>The Investigational Office of Mr. Chu</em>. They specialize in
          retracing lost documents and artifacts and are set to open doors on
          the 15th of October, 2023.
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default App;
