import Slider from "react-slick";

export default function Poems() {
  return (
    <Slider
      {...{
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      }}
    >
      <div className="poem">
        <p>In chambers locked where shadows rest,</p>
        <p>A gift is hidden from the quest.</p>
        <p>Not forged in fires or battles won,</p>
        <p>But spun in dances, moon to sun.</p>
        <p>
          <br />
        </p>
        <p style={{ fontWeight: "bold" }}>
          Fear not mistakes, nor erring path,
        </p>
        <p>This journey's not of trials and wrath.</p>
        <p>An adventure waits, in mystery clad,</p>
        <p>To find the lost, and make hearts glad.</p>
      </div>
      <div className="poem">
        <p>In chambers locked where memories wane,</p>
        <p>An ancient force lays hidden gain.</p>
        <p>Not forged in fires nor battles fought,</p>
        <p>But spun in dances, memory sought.</p>
        <p>
          <br />
        </p>
        <p>He feeds on past, both sweet and sad,</p>
        <p>And locks away what once you had.</p>
        <p>Fear not mistakes, nor erring way,</p>
        <p>For memory's light keeps dark at bay.</p>
        <p>
          <br />
        </p>
        <p>This journey's not of trials or dread,</p>
        <p>But a quest to find what time has shed.</p>
        <p>An adventure waits, in mystery shroud,</p>
        <p>To unlock the gift that's hidden and cowed.</p>
      </div>

      <div className="poem">
        <p>In city and wood, a treasure hunt calls,</p>
        <p>Echoes of memories, beckon and enthrall.</p>
        <p>From the heart of the forests to streets that we know,</p>
        <p>Decipher the signs, let the journey bestow.</p>
        <p>
          <br />
        </p>
        <p>With fire’s soft dance and a script from the eld,</p>
        <p>Reflections and fragments are secrets upheld.</p>
        <p>Maps torn and scattered, yet hold tales to recite,</p>
        <p>Join them together, to cast forth the light.</p>
        <p>
          <br />
        </p>
        <p>Through memories past, this hunt you must tread, </p>
        <p>Unravel the clues, to where they have led. </p>
        <p> Locked and concealed, a gift lies in wait, </p>
        <p> Unlock its secrets, before it's too late. </p>
      </div>
    </Slider>
  );
}
