import {
  Action,
  Thunk,
  action,
  createStore,
  createTypedHooks,
  thunk,
  persist,
} from "easy-peasy";

// const API_URL = "http://localhost:8000";
const API_URL = "https://baronlambert.dorigoluca.dev:13764";

export const enum Step {
  START = 0, // Just logged in, not started
  FIRST = 1, // She pressed "contact the memler"
  ASK_ELQUICODE = 2, // She went to the /internal/elquicode page and now has to enter the elquicode
  AFTERELQUICODE = 3, // She pressed "contact the memler" again
  GEOLOC1 = 4, // She went to the /internal/geoloc page and now has to allow geolocation
  FOUND_LOCATION = 5, // went to the right spot, now gets the enigma for sherlock as well as the SMSes
  AFTERSHERLOCKCODE = 6, // She entered the right password for sherlock
  AFTEREMAIL = 7, // She got the email and package
  X = 8, // X marks the spot
  FINAL = 9, // She got the final package
}

export interface StateModel {
  authToken?: string;
  isLoggedIn: boolean;

  missionStep: Step;
  setAuthToken: Action<StateModel, string | undefined>;

  setMissionStep: Action<StateModel, Step>;
  login: Thunk<
    StateModel,
    { username: String; password: String },
    any,
    StateModel
  >;
  triggerPrint: Thunk<StateModel, void, any, StateModel>;
  triggerSMS: Thunk<StateModel, void, any, StateModel>;
  triggerEmail: Thunk<StateModel, void, any, StateModel>;
}

const state: StateModel = {
  authToken: undefined,
  isLoggedIn: false,
  missionStep: Step.START,

  setAuthToken: action((state, payload) => {
    state.authToken = payload;
    state.isLoggedIn = true;
  }),

  setMissionStep: action((state, payload) => {
    state.missionStep = payload;
  }),

  login: thunk(async (actions, payload) => {
    const { username, password } = payload;
    const response = await fetch(`${API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: username, password: password }),
    });

    const data = await response.json();

    if (!response.ok) {
      console.log("response not ok");
      return false;
    } else {
      console.log("response ok");
      actions.setAuthToken(data.token);
      return true;
    }
  }),

  triggerPrint: thunk(async (actions, payload, { getStoreState }) => {
    const { authToken } = getStoreState();
    console.log("triggering print, auth token is", authToken);
    const response = await fetch(`${API_URL}/trigger_print`, {
      method: "GET",
      headers: {
        Authorization: `${authToken}`,
      },
    });

    const data = await response.json();
    console.log("triggered print, response is", data);
  }),

  triggerSMS: thunk(async (actions, payload, { getStoreState }) => {
    const { authToken } = getStoreState();
    const response = await fetch(`${API_URL}/trigger_sms`, {
      method: "GET",
      headers: {
        Authorization: `${authToken}`,
      },
    });

    const data = await response.json();
    console.log("triggered sms, response is", data);
  }),

  triggerEmail: thunk(async (actions, payload, { getStoreState }) => {
    const { authToken } = getStoreState();
    const response = await fetch(`${API_URL}/trigger_email`, {
      method: "GET",
      headers: {
        Authorization: `${authToken}`,
      },
    });

    const data = await response.json();
    console.log("triggered email, response is", data);
  }),
};

const store = createStore(persist(state));

const { useStoreActions, useStoreState, useStoreDispatch, useStore } =
  createTypedHooks<StateModel>();

export { store, useStore, useStoreActions, useStoreDispatch, useStoreState };
