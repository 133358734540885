import React, { useState } from "react";
import "./LoginPage.css";
import { Button } from "react-bootstrap";
import { useStoreState, useStoreActions } from "./state/globalStore";
import { Navigate } from "react-router-dom";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [shake, setShake] = useState(false);

  const { login } = useStoreActions((actions) => actions);

  const { isLoggedIn } = useStoreState((state) => state);

  const handleAnimationEnd = () => {
    setShake(false);
  };
  const handleLogin = async () => {
    if (!username || !password) {
      setError("Login and Password are required.");
      setShake(true); // Reset in case it was shaking before
      return;
    }
    const login_result = await login({ username, password });

    console.log(login_result);

    if (!login_result) {
      setError("Invalid login or password.");
      setShake(true);
    }
  };

  return (
    <div className="login-container">
      <div
        className={`login-card ${shake ? "incorrect-shake-animation" : ""}`}
        onAnimationEnd={handleAnimationEnd}
      >
        {isLoggedIn && <Navigate to="/internal/mission" />}
        <input
          type="text"
          placeholder="Login"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <p className="error-text">{error}</p>}
        <Button
          variant="secondary"
          onClick={handleLogin}
          style={{
            marginRight: "1rem",
          }}
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
